export const CodeTemplate = `function clamp(x, lower = 0.1, upper = 0.9) {
  if (x < lower) return lower;
  if (x > upper) return upper;
  return x;
}

function shrink(x, e0 = 0.1, e1 = 0.9) {
  return (x - e0) / (e1 - e0);
}

function smooth(x) {
  return x * x * (3 - 2 * x);
}

function smoothstep(x, e0 = 0.1, e1 = 0.9) {
  x = shrink(x, e0, e1);
  x = clamp(x, 0, 1);
  return smooth(x);
}

function bezier(x, o1=[0,0], o2=[0.7,0.1], o3 =[0.3,0.9], o4 = [1,1]) {
  // https://brilliant.org/wiki/cardano-method/
  let 
    pa = o1[0]-x,
    pb = o2[0]-x,
    pc = o3[0]-x,
    pd = o4[0]-x;

  let
    a = (  -pa + 3*pb - 3*pc + pd),
    b = ( 3*pa - 6*pb + 3*pc),
    c = (-3*pa + 3*pb),
    d = pa;

  let
    Q = (3*a*c - b*b) / (9*a*a),
    R = (9*a*b*c - 27*a*a*d - 2*b*b*b) / (54*a*a*a);

  let
    D = sqrt(R*R + Q*Q*Q);
    T = cbrt(R-D);
    S = cbrt(R+D);
  
  let x1 =  S + T - b / (3*a);

  let y1 = o1[1];
  let y2 = o2[1];
  let y3 = o3[1];
  let y4 = o4[1];

  return y1*(1-x1)*(1-x1)*(1-x1) + 3*y2*(1-x1)*(1-x1)*x1 + 3*y3*(1-x1)*x1*x1 + y4*x1*x1*x1;
}
bezier.color='#2060a0';
`;
