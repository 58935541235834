import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProgramResult, FunctionCallable, FunctionState } from "./Types";

const FunctionContainer = styled.div`
  border-bottom: 1px solid #cccccc;
  display: flex;
  padding: 6px;
  font-size: 14px;
`;
const FunctionName = styled.div`
  flex: 1;
  display: inline-flex;
  font-weight: 700;
`;
const FunctionParam = styled.div`
  display: inline-flex;
  line-height: 20px;
  font-size: 12px;
  min-width: 60px;
  margin-left: 5px;
`;
const FunctionParamValue = styled.input`
  border: none;
  background: #ddeeee;
  width: 30px;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const FunctionActive = styled.input``;
const FunctionParamName = styled.div`
  display: inline-block;
`;
const FunctionParamContainer = styled.div`
  display: block;
  input {
    display: block;
  }
`;

function FunctionItem(props: {
    func: FunctionCallable;
    state: FunctionState;
    onChange: (name: string, state: FunctionState) => void;
}) {
    const [enabled, setEnabled] = useState(true);

    const handleActive = (e: any) => {
        let checked = e.currentTarget.checked;

        console.log(checked);
        props.onChange(props.func.name, { ...props.state, enabled: checked });
        setEnabled(checked);
    };

    return (
        <FunctionContainer>
            <FunctionActive
                type="checkbox"
                checked={enabled}
                onChange={handleActive}
            />
            <FunctionName>{props.func.name}</FunctionName>
            {props.func.params.map((m) => (
                <FunctionParam key={m.name}>
                    <FunctionParamName>{m.name}:</FunctionParamName>
                    {Array.isArray(m.default) ? (
                        <FunctionParamContainer>
                            {m.default.map((m0) => (
                                <FunctionParamValue value={m0} />
                            ))}
                        </FunctionParamContainer>
                    ) : (
                        <FunctionParamValue value={m.default ?? 0} />
                    )}
                </FunctionParam>
            ))}
        </FunctionContainer>
    );
}

export default function FunctionList(props: {
    program: ProgramResult;
    onStateChange: (states: Record<string, FunctionState>) => void;
}) {
    const [states, setStates] = useState<Record<string, FunctionState>>({});

    useEffect(() => {
        let newStates: Record<string, FunctionState> = {};
        Object.keys(props.program.funcs).forEach((f) => {
            //let func = props.program.funcs[f];
            newStates[f] = {
                name: f,
                enabled: true,
                params: []
            };
        });
        setStates(newStates);
    }, []);

    const handleChange = (name: string, state: FunctionState) => {
        console.log("func", name, state);

        let newStates = {
            ...states
        };
        newStates[name] = state;
        setStates(newStates);
        props.onStateChange(newStates);
    };

    const getState = (key: string): FunctionState => {
        if (key in states) {
            return states[key];
        }

        return {
            name: key,
            enabled: true,
            params: []
        };
    };

    return (
        <div>
            {Object.keys(props.program.funcs).map((k) => (
                <FunctionItem
                    key={k}
                    func={props.program.funcs[k]}
                    state={getState(k)}
                    onChange={handleChange}
                />
            ))}
        </div>
    );
}
