import { useEffect, useRef, useState } from "react";
import { parseScript, Program } from "esprima";
import { FunctionType, ParamType, ProgramResult, ProgramError } from "./Types";
import * as ET from "estree";
import Editor, { useMonaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { CodeTemplate } from "./CodeTemplate";

function parseCode(code: string): ProgramResult | ProgramError | undefined {
    try {
        let result = parseScript(code);
        if (!result || !result.body) {
            return undefined;
        }

        let unident = (x: ET.Pattern): string => {
            if (x.type === "Identifier") {
                return x.name;
            } else if (x.type === "AssignmentPattern") {
                return unident(x.left);
            }
            return "";
        };

        let unvalue = (
            x: ET.Expression | ET.SpreadElement | null
        ): number | undefined | number[] => {
            if (!x) {
                return undefined;
            }

            if (x.type === "Literal" && typeof x.value === "number") {
                return x.value;
            } else if (x.type === "ArrayExpression") {
                let params: number[] = x.elements.map((e) => {
                    let v = unvalue(e);
                    if (typeof v === "number") return v;
                    return 0;
                });
                return params;
            }
            return undefined;
        };

        let unparam = (x: ET.Pattern): ParamType => {
            if (x.type === "Identifier") {
                return { name: x.name };
            } else if (x.type === "AssignmentPattern") {
                return { name: unident(x.left), default: unvalue(x.right) };
            }
            return { name: "" };
        };

        let functions: FunctionType[] = [];
        let vars: string[] = [];

        let items = result.body;
        for (let item of items) {
            //console.log("item", item);

            if (item.type === "FunctionDeclaration" && item.id) {
                let id = item.id.name;
                let params = item.params.map((param: any) => unparam(param));
                params.shift();
                // console.log("functoin", id, params);
                functions.push({ name: id, params: params });
                console.log(params);
            } else if (item.type === "VariableDeclaration") {
                if (item.kind === "const") continue;
                for (let decl of item.declarations) {
                    let name = unident(decl.id);
                    vars.push(name);
                }
            }
        }

        let funcNames =
            "{" +
            functions
                .map(
                    (f) =>
                        `${f.name}: {name: '${f.name}', func: ${f.name},params: ` +
                        JSON.stringify(f.params) +
                        `}`
                )
                .join(",") +
            "}";
        let varNames = "{" + vars.map((v) => `${v}: _ => ${v} = _`).join(",") + "}";
        let exporter = `({vars: ${varNames}, funcs: ${funcNames}})`;

        let importMath = Object.getOwnPropertyNames(Math)
            .map((m) => `const ${m}=Math.${m}`)
            .join(";");
        // console.log("impot", importMath);

        let toEval = importMath + ";" + code + ";\n" + exporter;
        // console.log(toEval);
        let evalResult = eval(toEval) as ProgramResult;

        return evalResult;
    } catch (e) {
        let err = e as any;
        if (err && "message" in err && "lineNumber" in err && "column" in err) {
            return {
                message: err.message,
                line: parseInt(err.lineNumber, 10),
                column: parseInt(err.column, 10)
            };
        }

        if (e instanceof Error && e.stack) {
            let stack = e.stack;
            let m = stack.match(/^\s+at eval.*:(\d+):(\d+)\)$/m);
            if (m) {
                //console.log("err", e);
                return {
                    message: e.message,
                    line: parseInt(m[1], 10),
                    column: parseInt(m[2], 10)
                };
            }

            //console.log("error!", stack);
        }
    }

    return undefined;
}

export default function EditorView(props: {
    onChange: (program: ProgramResult) => void;
}) {
    let [code, setCode] = useState("");
    let [errors, setErrors] = useState<string[]>([]);
    let refEditor = useRef<monaco.editor.IStandaloneCodeEditor>(null);

    const monaco = useMonaco();

    const updateCode = () => {
        let result = parseCode(code);
        console.log("UPDATE CODE", result);
        if (result && "funcs" in result) {
            props.onChange(result);
            if (errors.length) {
                refEditor.current?.deltaDecorations(errors, []);
                setErrors([]);
            }
        } else if (result && "message" in result) {
            //props.onCodeError(result);

            //console.log("REF", refEditor.current);
            if (!monaco) {
                return;
            }

            // setTimeout(() => {
            let newErrors = refEditor.current?.deltaDecorations(errors, [
                {
                    range: new monaco.Range(result.line || 1, 1, result.line || 1, 3),
                    //range: new monaco.Range(1, 1, 1, 3),
                    options: {
                        isWholeLine: true,
                        className: "string-error",
                        glyphMarginClassName: "glyph-error"
                    }
                }
            ]);
            if (newErrors) {
                setErrors(newErrors);
            }
        }
    };

    useEffect(() => updateCode(), [code]);

    useEffect(() => {
        console.log("Load stored");
        let stored = localStorage.getItem("funccode") || CodeTemplate;
        setCode(stored);
        updateCode();
    }, []);

    const handleChange = (text: string | undefined) => {
        if (!text || text === code) {
            return;
        }
        //let text = e.currentTarget.value;
        setCode(text);
        //updateCode();

        localStorage.setItem("funccode", text);
    };

    const handleEditorMount = (
        editor: monaco.editor.IStandaloneCodeEditor,
        monaco: any
    ) => {
        (refEditor as any).current = editor;
        //updateCode();
    };

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100%",
                display: "relative",
                maxHeight: "100% !important"
            }}
        >
            <Editor
                //ref={refEditor}
                onChange={handleChange}
                height="100vh"
                defaultLanguage="javascript"
                defaultValue={code}
                onMount={handleEditorMount}
            />
        </div>
    );
}
