import {
    Matrix,
    compose,
    scale,
    translate,
    applyToPoint,
    inverse
} from "transformation-matrix";
import { FunctionStates, ProgramResult } from "./Types";

type Point = { x: number; y: number };

type TextStyle = {
    size?: number;
    left?: boolean;
    right?: boolean;
    top?: boolean;
    bottom?: boolean;
    color?: string;
    marginX?: number;
    marginY?: number;
};

//let font = new FontFace('https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPq_ROW-AJi8SJQt.woff');

export default class ChartRender {
    private ctx!: CanvasRenderingContext2D;
    private width = 0;
    private height = 0;
    private matrix!: Matrix;
    private inverseMatrix!: Matrix;
    private square = false;

    render(
        canvas: HTMLCanvasElement,
        program: ProgramResult,
        funcStates: FunctionStates
    ) {
        //console.log("render", canvas, canvas.width, canvas.height);
        console.log("render", funcStates);
        this.width = canvas.width;
        this.height = canvas.height;

        let min = Math.min(this.width, this.height);

        this.createMatrix();

        this.ctx = canvas.getContext("2d")!;
        this.ctx.fillRect(0, 0, 100, 100);

        this.clear();
        this.drawGrid();

        let testFunc = (x: number) => 0.5 + Math.cos(x * Math.PI * 4) * 0.5;
        (testFunc as any).color = "#00ff00";

        //let color = (testFunc as any).color || "rgba(255,0,0,1)";
        //this.drawFunction(testFunc, color);

        let i = 0;
        for (let fname in program.funcs) {
            i++;

            if (funcStates[fname] && !funcStates[fname].enabled) {
                continue;
            }

            let r = Math.cos(i * 0.2) * 0.5 + 0.5;
            let g = Math.sin(i * 0.3) * 0.5 + 0.5;
            let b = r * g;

            let clr = Math.floor(r * 255);
            let clg = Math.floor(g * 255);
            let clb = Math.floor(b * 255);

            let f0 = program.funcs[fname];
            // console.log("render func", fname, f0);
            try {
                let zeroValue = f0.func(0);
            } catch (e) {
                // console.log(e);
                continue;
            }

            let func = (x: number) => f0.func(x);
            let color = (f0.func as any).color || selectColor(i, 10);

            this.drawFunction(func, color);
        }
    }

    private createMatrix() {
        if (this.square) {
            let sc = Math.min(this.width, this.height);
            this.matrix = compose(
                translate(this.width / 2, this.height / 2),
                scale(sc, -sc),
                translate(-0.5, 0.5),
                translate(0, -1),
                translate(0.5, 0.5),
                scale(0.8, 0.8),
                translate(-0.5, -0.5)
            );
        } else {
            this.matrix = compose(
                scale(this.width, -this.height),
                translate(0, -1),
                translate(0.5, 0.5),
                scale(0.8, 0.8),
                translate(-0.5, -0.5)
            );
        }
        this.inverseMatrix = inverse(this.matrix);
    }

    private clear() {
        // console.log("clear");
        this.ctx.fillStyle = "#ffffee";
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.fillRect(0, 0, this.width, this.height);
    }

    private drawFunction(fun: (x: number) => number, color: string) {
        //let fun = (x: number) => 0.5 + Math.cos(x * Math.PI * 4) * 0.5;

        let x0 = this.toView({ x: 0, y: 0 }).x;
        let x1 = this.toView({ x: 1, y: 0 }).x;
        let x2 = this.toView({ x: this.width, y: 0 }).x;
        let step = x1 - x0;
        let maxd = step * step;

        //console.log("x0-x1", x1 - x0, x0, x2);

        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = 2;
        this.stroke((ctx) => {
            let und = true;
            let px = x0;
            let py = 0;
            let spx = x0;
            let spy;
            for (let x = x0; x < x2; x += step) {
                let y;
                try {
                    y = fun(x);
                } catch (e) {
                    if (!und && spy !== undefined) {
                        ctx.lineTo(spx, spy);
                    }
                    spy = undefined;
                    und = true;
                    continue;
                }
                if (y === undefined) {
                    if (!und && spy !== undefined) {
                        ctx.lineTo(spx, spy);
                    }
                    spy = undefined;
                    und = true;
                    continue;
                }

                if (und) {
                    ctx.moveTo(x, y);
                    px = spx = x;
                    py = spy = y;
                    und = false;
                    continue;
                }

                let dx = px - x;
                let dy = py - y;
                let d = dx * dx + dy * dy;
                if (d > maxd) {
                    ctx.lineTo(x, y);
                    px = x;
                    py = y;
                }
                spx = x;
                spy = y;
            }
        });
    }

    private draw(callback: (ctx: CanvasRenderingContext2D) => void) {
        this.ctx.save();
        this.ctx.transform(
            this.matrix.a,
            this.matrix.b,
            this.matrix.c,
            this.matrix.d,
            this.matrix.e,
            this.matrix.f
        );

        callback(this.ctx);
        this.ctx.restore();
    }

    private stroke(callback: (ctx: CanvasRenderingContext2D) => void) {
        this.ctx.beginPath();
        this.draw(callback);
        this.ctx.stroke();
    }

    private toScene(pos: Point) {
        return applyToPoint(this.matrix, pos);
    }

    private toView(pos: Point) {
        return applyToPoint(this.inverseMatrix, pos);
    }

    private drawGrid() {
        let sp = this.toView({ x: 0, y: 0 });
        let ep = this.toView({ x: this.width, y: this.height });
        // console.log('sp', sp, ep);

        this.ctx.strokeStyle = "#000";
        //this.ctx.fillStyle = "rgba(200,200,255,0.1)";
        this.ctx.lineWidth = 0.1;

        this.stroke((ctx) => {
            // x grid
            for (let y = -0.1; y >= ep.y; y -= 0.1) {
                ctx.moveTo(sp.x, y);
                ctx.lineTo(ep.x, y);
            }
            for (let y = -0.1; y <= sp.y; y += 0.1) {
                ctx.moveTo(sp.x, y);
                ctx.lineTo(ep.x, y);
            }

            // y grid
            for (let x = 0; x <= ep.x; x += 0.1) {
                ctx.moveTo(x, sp.y);
                ctx.lineTo(x, ep.y);
            }
            for (let x = -0.1; x >= sp.x; x -= 0.1) {
                ctx.moveTo(x, sp.y);
                ctx.lineTo(x, ep.y);
            }
        });

        let ratioX = 1 / this.width / 0.8;
        let ratioY = 1 / this.height / 0.8;
        let arrow = (10 * (ratioX + ratioY)) / 2;

        this.ctx.strokeStyle = "#666666";
        this.ctx.fillStyle = "hsl(240,90%,50%,0.07)";
        this.ctx.lineWidth = 0.5;
        this.stroke((ctx) => {
            ctx.fillRect(0, 0, 1, 1);

            // x axis
            ctx.moveTo(0, 0);
            ctx.lineTo(1, 0);

            ctx.lineTo(1 - arrow, 0 + arrow / 3);
            ctx.moveTo(1, 0);
            ctx.lineTo(1 - arrow, 0 - arrow / 3);

            // y axis
            ctx.moveTo(0, 0);
            ctx.lineTo(0, 1);

            ctx.lineTo(0 - arrow / 2, 1 - arrow);
            ctx.moveTo(0, 1);
            ctx.lineTo(0 + arrow / 2, 1 - arrow);
        });

        this.text(0, 0, { size: 16, bottom: true, left: true }, "0");
        this.text(1, 0, { size: 16, bottom: true }, "1");
        this.text(0, 1, { size: 16, left: true }, "1");
        this.text(0, 0.5, { size: 16, left: true }, "y");
        this.text(0.5, 0, { size: 16, bottom: true }, "x");
    }

    private text(x: number, y: number, style: TextStyle, text: string) {
        if (style.size) {
            this.ctx.font = style.size + "px 'Roboto Mono'";
        } else {
            this.ctx.font = "20px 'Roboto Mono'";
        }
        this.ctx.fillStyle = style.color ?? "#000";

        let marginX = style.marginX ?? 5;
        let marginY = style.marginY ?? 5;

        let d = this.ctx.measureText(text);
        let pos = this.toScene({ x: x, y: y });
        //let pos = this.toScene({ x: 0, y: 0 });
        let w = d.width;
        let h = d.fontBoundingBoxAscent;
        let px = pos.x;
        let py = pos.y;
        if (style.bottom) {
            py += h + marginY;
        } else if (style.top) {
            py -= marginY;
        } else {
            py += h / 2;
        }

        if (style.left) {
            px -= w + marginX;
        } else if (style.right) {
            px += marginX;
        } else {
            px -= w / 2;
        }

        this.ctx.fillText(text, px, py);
    }

    private drawGrid3() {
        let xOffset = 50;
        let yOffset = 50;

        this.ctx.fillStyle = "#ccffcc";
        this.ctx.lineWidth = 0.5;
        this.ctx.save();
        this.ctx.scale(this.width, -this.height);
        this.ctx.translate(0, -1);
        this.ctx.translate(0.5, 0.5);
        this.ctx.scale(0.8, 0.8);
        this.ctx.translate(-0.5, -0.5);
        this.ctx.fillRect(0, 0, 1, 1);

        this.ctx.beginPath();
        // x axis
        this.ctx.moveTo(0, 0);
        this.ctx.lineTo(1, 0);
        // y axis
        this.ctx.moveTo(0, 0);
        this.ctx.lineTo(0, 1);
        this.ctx.restore();

        this.ctx.strokeStyle = "#000000";
        this.ctx.stroke();
    }
}

function selectColor(index: number, count: number): string {
    return "hsl(" + ((index * (360 / count)) % 360) + ",90%,40%)";
}
