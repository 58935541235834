import { useEffect, useState, useRef, useMemo } from "react";
import ChartRender from "./ChartRender";
import { FunctionStates, FunctionType, ProgramResult } from "./Types";

export default function Chart(props: {
    program: ProgramResult;
    funcStates: FunctionStates;
}) {
    const [canvas, setCanvas] = useState({ width: 100, height: 100 });
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const renderer = useMemo(() => new ChartRender(), []);

    useEffect(
        () => renderer.render(canvasRef.current!, props.program, props.funcStates),
        [renderer, canvasRef, canvas, props.program, props.funcStates]
    );

    useEffect(() => {
        if (!containerRef.current || !canvasRef.current) {
            return;
        }

        let size = containerRef.current.getBoundingClientRect();
        if (canvas.width === size.width && canvas.height === size.height) {
            return;
        }

        setCanvas({ width: size.width, height: size.height });
        //renderer.render(canvasRef.current);
    });

    return useMemo(
        () => (
            <div
                ref={containerRef}
                style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "100%"
                }}
            >
                <canvas ref={canvasRef} width={canvas.width} height={canvas.height} />
            </div>
        ),
        [canvas]
    );
}
