import React from 'react';
import './App.css';
import "react-reflex/styles.css";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import EditorView from "./EditorView";
import Chart from "./Chart";
import FunctionList from "./FunctionList";
import { FunctionState, FunctionStates, ProgramResult } from "./Types";
import { useState } from "react";



function App() {
  const [pr, setPR] = useState<ProgramResult>({ vars: {}, funcs: {} });
  const [funcState, setFuncState] = useState<FunctionStates>({});

  const handleChange = (program: ProgramResult) => {
    //console.log("handle", program);
    setPR(program);
  };
  const handleSateChange = (states: Record<string, FunctionState>) => {
    setFuncState(states);
  };

  return (
      <ReflexContainer orientation="vertical">
        <ReflexElement className="left-pane">
          <EditorView onChange={handleChange} />
        </ReflexElement>

        <ReflexSplitter />

        <ReflexElement propagateDimensions={true}>
          <ReflexContainer orientation="horizontal" windowResizeAware={true}>
            <ReflexElement className="funcs-pane">
              <FunctionList program={pr} onStateChange={handleSateChange} />
            </ReflexElement>
            <ReflexSplitter />
            <ReflexElement
                className="chart-pane"
                propagateDimensions={true}
                flex={0.7}
            >
              <Chart program={pr} funcStates={funcState} />
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>
      </ReflexContainer>
  );
}

export default App;
